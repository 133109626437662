import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import loadingImage from "./assets/loading.gif";

const App = lazy(() => import("./App"));

const loadingOverlay = {
  width: "100px",
  height: "100px",
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "transparent",
};

const loadingImageStyle = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
};

ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div className="loading-overlay" style={loadingOverlay}>
          <img src={loadingImage} alt="" style={loadingImageStyle} />
        </div>
      }
    >
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
